<template>
  <div class="w-screen">
    <div class="container grid grid-cols-12 gap-8 ">
      <div class="col-start-4 col-span-9">
        <h1 class="font-bold text-2xl capitalize">
          {{ company.name }}
        </h1>
      </div>
      <!-- <div class="col-span-3">
        <div class="mb-4">
          <router-link
            :to="menu.link"
            class="flex items-center border-t border-gray-800 px-2 py-2 cursor-pointer hover:bg-gray-100"
            active-class="active-sub"
            v-for="(menu, m) in activeItems"
            :key="m"
          >
            <p class="flex-grow">
              {{ menu.label }}
            </p>
            <i class="mdi mdi-chevron-right text-2xl"></i>
          </router-link>
        </div>
      </div> -->
      <div class="col-span-12">
        <router-view></router-view>
      </div>
      <!-- <pre>
      {{ currentUserData }}
    </pre
      > -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
// import { API_URL } from "@/app-axios.js";
// import Axios from "axios";

// import { Auth } from "aws-amplify";

export default {
  data() {
    return {
      menuItems: [
        {
          link: `/${this.$route.params.lang}/store/admin/editstore`,
          label: "Il mio Negozio",
          active: true
        },
        {
          link: `/${this.$route.params.lang}/store/admin/addproduct`,
          label: "Aggiungi Prodotti",
          active: true
        },
        {
          link: `/${this.$route.params.lang}/store/admin/editproducts`,
          label: "Visualizza Prodotti",
          active: true
        },
        {
          link: `/${this.$route.params.lang}/store/admin/orders-received`,
          label: "Ordini Ricevuti",
          active: true
        },
        {
          link: `/${this.$route.params.lang}/store/admin/orders-sent`,
          label: "Ordini Effettuati",
          active: false
        }
      ]
    };
  },
  async mounted() {
    console.log("COMPANY ID ON MOUNTED ", this.companyId);
    if (!this.companyId) {
      await this.$store.dispatch("auth/getCurrentUser");
      console.log("Company Id non esiste. lo cerco", this.currentUserData);
      this.$store.commit(
        "products/SET_COMPANY_ID",
        this.$store.getters["auth/companyId"]
      );
    }
    console.log(
      "%cCarico le informazioni sullo store",
      "color:teal; font-size:1.3rem",
      this.companyId
    );
    //await this.loadStore();
  },

  computed: {
    ...mapGetters("auth", ["currentUserData"]),
    ...mapGetters("products", ["companyId"]),
    ...mapState("createstore", ["company"]),
    activeItems() {
      return this.menuItems.filter(m => m.active);
    }
  },
  methods: {
    ...mapActions("createstore", ["loadStore"]),
    ...mapMutations("createstore", [
      "SET_COMPANY_COVER_PHOTO",
      "SET_COMPANY_PROFILE_PHOTO"
    ])
  }
};
</script>

<style lang="postcss">
.active-sub {
  @apply bg-gray-100 font-bold text-primary-500;
}
</style>
